import React from "react";

import Layout from '../components/layout'
import SEO from "../components/seo";

export default () => (
    <Layout>
        <SEO title="Privacy Policy" description="The Seventa privacy policy" />
        <div className="policy-container">
            <header className="policy-header">
                <p className="policy-grey-text">Privacy Policy</p>
                <h1>Your privacy is important to us</h1>
            </header>
            <div className="policy-content">
            <p>Effective date: May 10, 2018</p>

	<p>Seventa Events Ltd Trading as Seventa Events (&#8220;us&#8221;, &#8220;we&#8221;, or &#8220;our&#8221;) operates the https://www.seventa.co.uk/ website (the &#8220;Service&#8221;).</p>
	<p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
	<p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from https://www.seventa.co.uk/</p>
	<p>&nbsp;</p>
	<p class="title-lg"><strong>Definitions</strong></p>
	<p><strong>Personal Data</strong></p>
	<p>Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
	<p><strong>Usage Data</strong></p>
	<p>Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</p>
	<p><strong>Cookies</strong></p>
	<p>Cookies are small pieces of data stored on a User’s device.</p>
	<p><strong>Data Controller</strong></p>
	<p>Data Controller means a person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed.</p>
	<p>For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
	<p><strong>Data Processor (or Service Providers)</strong></p>
	<p>Data Processor (or Service Provider) means any person (other than an employee of the Data Controller) who processes the data on behalf of the Data Controller.</p>
	<p>We may use the services of various Service Providers in order to process your data more effectively.</p>
	<p><strong>Data Subject</strong></p>
	<p>Data Subject is any living individual who is the subject of Personal Data.</p>
	<p><strong>User</strong></p>
	<p>The User is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
	<p>&nbsp;</p>
	<p class="title title-lg">Information Collection And Use</p>
	<p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
	<p><strong>Types of Data Collected</strong></p>
	<p><strong>Personal Data</strong></p>
	<p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&#8220;Personal Data&#8221;). Personally identifiable information may include, but is not limited to:</p>
	<p>· Email address 
	· First name and last name 
	· Phone number 
	· Address, Town, County, Postal code, City 
	· Cookies and Usage Data</p>
	<p>We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.</p>
	<p><strong>Usage Data</strong></p>
	<p>We may also collect information how the Service is accessed and used (&#8220;Usage Data&#8221;). This Usage Data may include information such as your computer&#8217;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
	<p><strong>Tracking &amp; Cookies Data</strong></p>
	<p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information. 
	Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyse our Service.</p>
	<p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
	<p>Examples of Cookies we use:</p>
	<p>· Session Cookies. We use Session Cookies to operate our Service. 
	· Preference Cookies. We use Preference Cookies to remember your preferences and various settings. 
	· Security Cookies. We use Security Cookies for security purposes.</p>
	<p class="title title-lg">Use of Data</p>
	<p>Seventa Events Ltd Trading as Seventa Events uses the collected data for various purposes:</p>
	<ul>
		<li>To provide and maintain our Service</li>
		<li>To notify you about changes to our Service</li>
		<li>To allow you to participate in interactive features of our Service when you choose to do so</li>
		<li>To provide customer support</li>
		<li>To gather analysis or valuable information so that we can improve our Service</li>
		<li>To monitor the usage of our Service</li>
		<li>To detect, prevent and address technical issues</li>
		<li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li>
	</ul>
	<p>&nbsp;</p>
	<p class="title-lg">Retention of Data</p>
	<p>Seventa Events Ltd Trading as Seventa Events will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
	<p>Seventa Events Ltd Trading as Seventa Events will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
	<p>&nbsp;</p>
	<p class="title title-lg">Transfer Of Data</p>
	<p>Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
	<p>If you are located outside United Kingdom and choose to provide information to us, please note that we transfer the data, including Personal Data, to United Kingdom and process it there.</p>
	<p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
	<p>Seventa Events Ltd Trading as Seventa Events will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
	<p>&nbsp;</p>
	<p class="title title-lg">Disclosure Of Data</p>
	<p><strong>Disclosure for Law Enforcement</strong></p>
	<p>Under certain circumstances, Seventa Events Ltd Trading as Seventa Events may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
	<p><strong>Legal Requirements</strong></p>
	<p>Seventa Events Ltd Trading as Seventa Events may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
	<ul>
		<li>To comply with a legal obligation</li>
		<li>To protect and defend the rights or property of Seventa Events Ltd Trading as Seventa Events</li>
		<li>To prevent or investigate possible wrongdoing in connection with the Service</li>
		<li>To protect the personal safety of users of the Service or the public</li>
		<li>To protect against legal liability</li>
	</ul>
	<p>&nbsp;</p>
	<p><strong>Security Of Data</strong></p>
	<p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
	<p><strong>Your Rights</strong></p>
	<p>Seventa Events Ltd Trading as Seventa Events aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
	<p>Whenever made possible, you can update your Personal Data directly within your account settings section. If you are unable to change your Personal Data, please contact us to make the required changes.</p>
	<p>If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.</p>
	<p>In certain circumstances, you have the right:</p>
	<ul>
		<li>To access and receive a copy of the Personal Data we hold about you</li>
		<li>To rectify any Personal Data held about you that is inaccurate</li>
		<li>To request the deletion of Personal Data held about you</li>
	</ul>
	<p>&nbsp;</p>
	<p>You have the right to data portability for the information you provide to Seventa Events Ltd Trading as Seventa Events. You can request to obtain a copy of your Personal Data in a commonly used electronic format so that you can manage and move it.</p>
	<p>Please note that we may ask you to verify your identity before responding to such requests.</p>
	<p>&nbsp;</p>
	<p class="title title-lg">Service Providers</p>
	<p>We may employ third party companies and individuals to facilitate our Service (&#8220;Service Providers&#8221;), to provide the Service on our behalf, to perform Service-related services or to assist us in analysing how our Service is used.</p>
	<p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
	<p><strong>Analytics</strong></p>
	<p>We may use third-party Service Providers to monitor and analyse the use of our Service.</p>
	<p><strong>Google Analytics</strong></p>
	<p>Google Analytics is a web analytics service offered by Google that tracks 
	and reports website traffic. Google uses the data collected to track and 
	monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalize the ads of its own advertising network.</p>
	<p>You can opt-out of having made your activity on the Service available to Google Analytics by installing the Google Analytics opt-out browser add-on. The add-on prevents the Google Analytics JavaScript (ga.js, analytics.js, and dc.js) from sharing information with Google Analytics about visits activity.</p>
	<p>For more information on the privacy practices of Google, please visit the Google Privacy &amp;amp; Terms web page: &lt;http://www.google.com/intl/en/policies/privacy/&gt;</p>
	<p>&nbsp;</p>
	<p class="title title-lg">Links To Other Sites</p>
	<p>Our Service may contain links to other sites that are not operated by us. If you click on a third-party link, you will be directed to that third party&#8217;s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
	<p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third-party sites or services.s.</p>
	<p><strong>Children&#8217;s Privacy</strong></p>
	<p>Our Service does not address anyone under the age of 18 (&#8220;Children&#8221;).</p>
	<p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Children has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>
	<p><strong>Changes To This Privacy Policy</strong></p>
	<p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
	<p>We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &#8220;effective date&#8221; at the top of this Privacy Policy.</p>
	<p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
	<p><strong>Contact Us</strong></p>
	<p>If you have any questions about this Privacy Policy, please contact us:</p>
	<p>By email: <a class="__cf_email__" data-cfemail="aec7c0c8c1eeddcbd8cbc0dacf80cdc180dbc5" href="/cdn-cgi/l/email-protection">[email&#160;protected]</a></p>
	<p>&nbsp;</p>
	<p>&nbsp;</p>
	<h3><strong>Terms &amp; Conditions</strong></h3>
	<p>&nbsp;</p>
	<p class="p2">Address: Seventa Events, Lion Works Building, 543 Wallisdown Road, Bournemouth, Dorset, BH12 5AD Seventa Events Ltd trading as &#8216;Seventa Events&#8217;. Registered Address: Unit 2, 446 Commercial Road, Aviation Business Park, Christchurch, Dorset, BH23 6NW. Registered in England and Wales. Company Number: 07577672. VAT Number: GB 109 8047 11</p>
	<p class="p3">Seventa Events – Terms &amp; Conditions</p>
	<p class="p3"><b>1. Definitions</b></p>
	<ol class="ol1">
		<li class="li4">a. The &#8216;Contract&#8217; shall refer to these terms and conditions, all attached schedules, event documentation, proposals and correspondence.</li>
		<li class="li4">b. The &#8216;Correspondence&#8217; shall refer to all proposals, letters, emails, telephone conversations and any other form of communication in relation to the event.</li>
		<li class="li4">c. The &#8216;Client&#8217; refers to the customer described in all Correspondence, event documentation, proposals and schedules.</li>
		<li class="li4">d. The &#8216;Event&#8217; means all services, drinks, entertainment and meals as described in the Correspondence, event documentation, schedules and proposals.</li>
		<li class="li4">e. &#8216;Seventa Events&#8217; shall refer to Seventa Events ltd trading as Seventa Events.</li>
		<li class="li3">f. &#8216;Bookings&#8217; mean any event booked and agreed between the Client and Seventa Events.</li>
	</ol>
	<p class="p3"><b>2. Acceptance</b></p>
	<ol class="ol1">
		<li class="li4">a. Unless otherwise stated in writing by a director of Seventa Events; all Orders, Bookings and/or Events accepted by Seventa Events are subject to the under mentioned terms and conditions.</li>
		<li class="li4">b. No binding contract shall be formed until Seventa Events has received confirmation of an order by notice in writing from the Client, and that order is accepted in writing by Seventa Events. Furthermore, Seventa Events shall be under no obligation to perform the contract unless the Client has paid the agreed deposits defined below.</li>
		<li class="li4">c. Seventa Events reserves the right to accept clear instructions (either orally or in writing) from a Client to proceed with the Event and to enter into commitments on behalf of a Client. The Client shall thereon be liable for all costs and expenses incurred by Seventa Events in pursuance of the Client&#8217;s instructions and shall in all other respects be liable to Seventa Events for its fees, charges and commissions.</li>
		<li class="li3">d. A confirmation means that (at Seventa Events’ discretion and subject to receipt of payment) Seventa Events will proceed to arrange the Event and book facilities. It does not mean that the facilities are confirmed to be available and booked. All Confirmations are subject to availability.</li>
	</ol>
	<p class="p3"><b>3. Payment</b></p>
	<ol class="ol1">
		<li class="li4">a. <b>Deposits</b>: a deposit to be agreed at the time of confirmation must accompany all orders accepted by Seventa Events. No such deposits are returnable, except at the sole discretion of Seventa Events.</li>
		<li class="li4">b. The balance of all amounts due for an Event must be paid four weeks prior to the Event taking place unless otherwise agreed in writing by Seventa Events and the Client.</li>
		<li class="li4">c. All prices are exclusive of VAT, unless otherwise stated, which shall be payable at the rate(s) ruling at the date of invoice.</li>
		<li class="li4">d. Any extra expenses incurred over and above the total anticipated cost of the Event are to be paid upon receipt of invoice.</li>
		<li class="li4">e. All charges unpaid after their respective final date for payment shall carry interest on the amounts outstanding at a rate of 2% above the Bank of England base rate to be calculated on a daily basis.</li>
		<li class="li4">f. Seventa Events reserves the right to cancel or suspend the performance of the contract if payment dates are not adhered to by the Client.</li>
		<li class="li4">g. All payments due from the Client shall be made without any set-off, deduction or deferment of any nature.</li>
		<li class="li4">h. Any queries arising from the invoice must be communicated to Seventa Events within 14 days of date of the invoice. Failure to notify Seventa Events of any query within these 14 days will render the full invoice payable on the due date.</li>
		<li class="li4">i. Save where a fixed price has been agreed and recorded in writing by Seventa Events, all prices quoted by Seventa Events may be amended upon notice to the Client in the event of errors or omissions in any quotation or Correspondence or where an increase or decrease of price is caused by a change in circumstances beyond the reasonable control of Seventa Events, including increases or decreases in prices imposed upon Seventa Events by its suppliers in connection with an Event.</li>
		<li class="li3">j. If Seventa Events, in its absolute discretion and in writing, waives the conditions relating to payment for an event as set out in this clause, such waiver shall not prejudice the rights of Seventa Events to recover from the Client the amount which would otherwise have been payable under these terms and conditions in the event of a cancellation or amendment of a Booking.</li>
	</ol>
	<p class="p2">Address: Seventa Events, Lion Works Building, 543 Wallisdown Road, Bournemouth, Dorset, BH12 5AD Seventa Events Ltd trading as &#8216;Seventa Events&#8217;. Registered Address: Unit 2, 446 Commercial Road, Aviation Business Park, Christchurch, Dorset, BH23 6NW. Registered in England and Wales. Company Number: 07577672. VAT Number: GB 109 8047 11</p>
	<p class="p3"><b>4. Numbers</b></p>
	<ol class="ol1">
		<li class="li4">a. All Bookings of whatever nature are accepted at the discretion of Seventa Events and on the understanding that the number of persons for whom the event is arranged is to be regarded as the minimum and that no refund or allowance shall be made by Seventa Events if fewer persons actually attend the Event.</li>
		<li class="li4">b. After confirmation Seventa Events will endeavour to accommodate any reasonable increases in attendance numbers, but will be limited to the maximum capacities supplied by the Venue or any other relevant suppliers.</li>
		<li class="li3">c. If the numbers are varied from the original quotation or Correspondence at the Client&#8217;s request Seventa Events reserves the right to submit a revised quotation for the Event.</li>
	</ol>
	<p class="p3"><b>5. Cancellation</b></p>
	<ol class="ol1">
		<li class="li4">a. If the client wishes to cancel an Event, notice of cancellation from the Client must be made in writing to Seventa Events.</li>
		<li class="li4">b. Seventa Events reserves the right to charge the Client a cancellation fee in the event that the Client provides Seventa Events with a written notice of cancellation. The cancellation fee will be calculated as the greater amount of either: I. 50% of the total expenses that Seventa Events has incurred in respect of all preparations, construction, orders and provision of goods and services for the Client at the cancelled event; or</li>
		<li class="li4">II. If the cancellation notice is received by Seventa Events before midnight on the 70th day prior to the Event, 50% of the total event cost as per the details in the event documentation;</li>
		<li class="li4">III. If the cancellation notice is received by Seventa Events after midnight on the 70<span class="s3">th</span> day prior to the event, but before midnight on the 43rd day prior to the Event, 75% of the total event cost as per the details in the event documentation;</li>
		<li class="li3">IV. If the cancellation notice is received by Seventa Events after midnight on the 43<span class="s3">rd</span> day prior to the Event, 100% of the total event cost as per the details in the event documentation.</li>
		<li class="li5"></li>
		<li class="li3">c. Seventa Events may cancel an order, Booking or Event at any time, upon providing written notice to the Client, if:</li>
	</ol>
	<p class="p3">i) The Client is in breach of any of the Terms and Conditions including, without limitation, any term relating to payment; or</p>
	<p class="p3">ii) The Client, being a company, has had a winding up petition presented against it, has entered into administration, receivership, liquidation or an arrangement with its creditors; or</p>
	<p class="p3">iii) The Client, being an individual, has had a bankruptcy petition presented against them, has become bankrupt, insolvent or entered into an arrangement with their creditors;</p>
	<ol class="ol2">
		<li class="li3">d. If the Client intends to use the Event(s) booked as a competition prize, this must be disclosed to Seventa Events on Booking who may have to seek permission from some or all of Seventa Events’ suppliers. Should permission be refused, Seventa Events reserves the right to cancel the Booking.</li>
	</ol>
	<p class="p3"><b>6. Amendments</b></p>
	<ol class="ol1">
		<li class="li4">a. If the Client wishes to change a confirmed order, Seventa Events must receive from the Client a written statement to this effect, and the request will be effective from the date of receipt by Seventa Events.</li>
		<li class="li4">b. It is not guaranteed that any requests for amendment will be satisfied, however Seventa Events will make its best efforts to accommodate the amendments, subject to availability and payment by the Client of an Amendment Fee.</li>
		<li class="li3">c. An Amendment fee will only be applicable to the Client’s amendment in the event that Seventa Events incurs any additional costs as a result of the amendment.</li>
	</ol>
	<p class="p3"><b>7. Limitation</b></p>
	<ol class="ol1">
		<li class="li4">a. Seventa Events, or its employees, will not under any circumstances be liable to the Client in contract, tort (including negligence) or otherwise for loss of revenues or opportunities, goodwill, reputation or any type of special, indirect or consequential loss (even if such loss was reasonably foreseeable or Seventa Events had been advised of the possibility of the Client incurring the same).</li>
		<li class="li4">b. Any loss or damage to equipment or property connected in any way to the Event will be the responsibility of the Client and all replacement and repair costs will be charged in full.</li>
		<li class="li4">c. Seventa Events does not accept any responsibility for the loss or damage of personal property belonging to Clients or their guests.</li>
		<li class="li3">d. Neither Seventa Events nor its employees shall be liable for any death, injury, damage, loss, illness, delay or expense caused to the Client, its employees, licensees or invitees or any other person or any other persons</li>
	</ol>
	<p class="p2">Address: Seventa Events, Lion Works Building, 543 Wallisdown Road, Bournemouth, Dorset, BH12 5AD Seventa Events Ltd trading as &#8216;Seventa Events&#8217;. Registered Address: Unit 2, 446 Commercial Road, Aviation Business Park, Christchurch, Dorset, BH23 6NW. Registered in England and Wales. Company Number: 07577672. VAT Number: GB 109 8047 11</p>
	<ol class="ol1">
		<li class="li4">attending the booked Event except insofar as such death, injury, loss, illness, delay or expense results from either the proven negligence or a deliberate action or omission of Seventa Events. Seventa Events’ liability shall in all cases be limited to the sums paid by the Client and exclude all indirect or consequential loss of whatever nature. Nothing in this clause shall affect Seventa Events liability for death or personal injury caused by its negligence, for which Seventa Events maintains insurance of up to £10million per claim or series of related claims.</li>
		<li class="li4">e. The Client agrees to indemnify Seventa Events against any injury, damage, loss, illness, delay or expense caused to the Venue, its employees, licensees or invitees or any other person or persons attending the booked Event except insofar as such injury, loss, illness, delay or expense results from either the proven negligence or deliberate action or omission of Seventa Events.</li>
		<li class="li4">f. The Client warrants that adequate insurance cover is held to cover such risks.</li>
		<li class="li4">g. Seventa Events relies entirely on its suppliers for correct information and, whilst all details are given in good faith, Seventa Events cannot accept responsibility for any inaccuracies in respect of information provided by its Suppliers.</li>
		<li class="li3">h. Sub-contractors may be engaged on behalf of the Client. The liability of Seventa Events in their failure to perform is limited to the value of the sub-contractor&#8217;s services.</li>
	</ol>
	<p class="p3"><b>8. Third Parties</b></p>
	<ol class="ol1">
		<li class="li4">a. Should for any reason, an Event be wholly or partly cancelled or postponed owing to circumstances beyond the control of Seventa Events(‘Force Majeure Events’), Seventa Events shall not be liable to refund any part of the fees paid by the client, save for refunds that will be made where Seventa Events are able to secure a refund on the bookings that they themselves have made in connection with a particular Event and, should such a refund be made, then Seventa Events will account to the Client having made a deduction of 10% for administration charges.</li>
		<li class="li4">b. Whilst Seventa Events do not exercise direct control over the running of hotels, venues, caterers, entertainers and other services, Seventa Events will always endeavour to protect the interest of its Clients by vigilant selection of third parties contracted to supply within its packages.</li>
		<li class="li3">c. Seventa Events will not be held liable for any loss, damage or disappointment due to unforeseeable or unavoidable circumstances beyond the control of Seventa Events, including but not limited to Force Majeure Events.</li>
	</ol>
	<p class="p3"><b>9. Insurance</b></p>
	<ol class="ol1">
		<li class="li4">a. Insurance for any Events, Damage, Personal Accident or Cancellation is not included, unless otherwise specified.</li>
		<li class="li4">b. It is advised that the Client takes out an insurance policy of its own to cover personal accidents, cancellation and other possible effects, should this not be included in any proposal from Seventa Events.</li>
		<li class="li3">c. Assistance in arranging other insurance in connection with any booked Event is offered by Seventa Events to the Client.</li>
	</ol>
	<p class="p3"><b>10. Conditions/Force Majeure</b></p>
	<ol class="ol1">
		<li class="li4">a. Seventa Events shall bear no liability for loss damage delay cancellation or failure of performance however arising if it has been caused by circumstances outside of its control including (but not limited to) Acts of God, civil commotion, strike, work to rule or go slow, lock-outs, hostilities, acts of terror, fire, flood, exceptional adverse weather conditions, drought or inability to procure materials, venues or services except at increased prices due to any of the foregoing clauses (and in these circumstances Seventa Events may suspend or cancel the whole or any part of the Event). Seventa Events will endeavour to notify the Client as quickly as reasonably possible if such a Force Majeure event occurs and, a cancellation having been made, refunds will be dealt with in accordance with clause 5.</li>
		<li class="li4">b. Seventa Events shall not be liable for any change in the Music and Dance License or the Alcohol and Beverage License issued to venues contracted by Seventa Events.</li>
		<li class="li4">c. The liability of Seventa Events in respect of all claims arising under any contract shall be limited to the amount of the price or charges payable to Seventa Events under such contract.</li>
		<li class="li4">d. If property belonging to Seventa Events (or supplier to Seventa Events) is willfully or negligently lost, damaged or stolen at an Event, the replacement cost is payable by the Client within 7 days from the date of the Event.</li>
		<li class="li4">e. Accidental damage, loss or injury at an Event should be covered by the Client&#8217;s own insurance and no claim can be accepted by Seventa Events.</li>
		<li class="li4">f. Seventa Events shall not be responsible for possessions brought to the Event by the Client or its guests; such possessions are brought to Events at the Client’s own risk.</li>
		<li class="li3">g. The rights granted to the Client under this agreement may not be transferred or assigned to a third party without</li>
	</ol>
	<p class="p2">Address: Seventa Events, Lion Works Building, 543 Wallisdown Road, Bournemouth, Dorset, BH12 5AD Seventa Events Ltd trading as &#8216;Seventa Events&#8217;. Registered Address: Unit 2, 446 Commercial Road, Aviation Business Park, Christchurch, Dorset, BH23 6NW. Registered in England and Wales. Company Number: 07577672. VAT Number: GB 109 8047 11</p>
	<ol class="ol1">
		<li class="li4">the prior written consent of Seventa Events.</li>
		<li class="li4">h. This contract supersedes and excludes all prior representations, discussions, understandings or agreements in relation to the subject matter of this contract. The terms of this contract may not be modified except by agreement in writing signed by both parties or their representatives.</li>
		<li class="li3">i. A failure to exercise or any delay or forbearance in exercising any right or remedy in relation to the contract shall not operate as a waiver of such right or remedy.</li>
	</ol>
	<p class="p3"><b>11. Confidentiality</b></p>
	<p class="p3">All event documentation is intended for the recipient only and may not be disclosed or provided to any third party without the written consent of Seventa Events.</p>
	<p class="p3"><b>12. Special Conditions</b></p>
	<ol class="ol1">
		<li class="li6">a. Seventa Events reserves the right to add further special conditions for specific bookings as and where considered appropriate. Any such conditions will be notified to the Client in writing prior to the Client’s confirmation and acceptance of those conditions.</li>
		<li class="li3"><span class="s4">b.</span> Seventa Events CCTV and film cameras may be present at events organised by us, this includes filming by the police or security staff carried out for the security of attendees, and filming and/or photography by Seventa Events, their employees and/or agents. By entering into this agreement you give your express consent and confirmation that you have the consent of all persons attending the event to all such filming and to your and your customers actual or simulated likeness being included within any film, photograph, audio and/or audio visual recording (“Footage”), all rights in which will be owned by Seventa Events or the relevant third party. You also agree that any such Footage may be used by us in any and all media for any purpose at any time throughout the world, including (with the exception of CCTV or security Footage) for commercial purposes, such as merchandising or DVDs, without payment or compensation to you.</li>
	</ol>
	<p class="p3"><b>13. Warranty and Limitation</b></p>
	<ol class="ol1">
		<li class="li4">a. Seventa Events warrants that the services supplied by it will be provided with reasonable skill and care. Seventa Events’ liability under this warranty shall be limited as specified in these terms.</li>
		<li class="li3">b. The warranty given above is given in lieu of and shall be deemed to exclude all other warranties and conditions whether express or implied and whether arising by common law, statute or otherwise.</li>
	</ol>

            </div>
        </div>
    </Layout>
)